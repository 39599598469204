<template>
  <div>
    <BBtn v-if="showNew" v-bind:to="{ name: to }" class="float-right ml-1">New Widget</BBtn>
    <BBtn v-if="showEdit" v-bind:to="{ name: to, params: { id } }" class="float-right">
      <BIconGear aria-label="Edit" />
    </BBtn>
    <BBtn v-if="showPerma" v-bind:to="{ name: to, params: { id } }" class="float-right">
      <BIconArrowLeft aria-label="View" />
    </BBtn>
    <h1 class="h2" v-if="title">
      {{ title }}
    </h1>
    <slot v-else />
    <small v-if="description" class="text-muted">{{ description }}</small>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      required: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    showNew: {
      type: Boolean,
      default: false
    },
    showPerma: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    to: {
      type: String,
      required: false
    }
  }
};
</script>
