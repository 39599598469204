<template>
  <div>
    <h2 class="h3">{{ title }}</h2>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
